<template>
  <div>....</div>
</template>

<script>
export default {
  created() {
    if (this.$route.query.url) {
      this.$router.push(this.$route.query.url);
    } else {
      this.$router.push("/login");
    }
  },
};
</script>

<style></style>
